import {
  Datagrid,
  List,
  Show,
  SimpleShowLayout,
  DateField,
  BooleanField,
  TextField,
  ShowButton,
  useRecordContext,
} from 'react-admin';

import { PackageEventsButton } from './../PackageEvents';
import { LoginHistoryEventsButton } from './../LoginHistoryEvents';
import { LogsButton } from './../Logs';

const OrganizationTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>Organization "{record.Name}"</span>;
};

export const OrganizationList = () => {
  return (
    <List empty={false} sort={{ field: 'created_at', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false}>
        <TextField source='OrganizationId' label='Organization Id' />
        <TextField source='Name' label='Name' />
        <DateField source='created_at' showDate showTime label='Created At' />
        <TextField source='Type' label='Type' />
        <TextField source='InstanceName' label='Instance Name' />
        <BooleanField source='IsSandbox' label='Is Sandbox' />
        <TextField source='URL' label='URL' />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const OrganizationShow = () => {
  return (
    <Show title={<OrganizationTitle />}>
      <SimpleShowLayout>
        <TextField source='id' label='Id' />
        <DateField source='created_at' showDate showTime label='Created At' />
        <TextField source='OrganizationId' label='Organization Id' />
        <TextField source='Name' label='Name' />
        <TextField source='Type' label='Type' />
        <TextField source='InstanceName' label='Instance Name' />
        <BooleanField source='IsSandbox' label='Is Sandbox' />
        <TextField source='ProductionOrgId' label='Production Org Id' />
        <TextField source='URL' label='URL' />
        <PackageEventsButton />
        <LoginHistoryEventsButton />
        <LogsButton />
      </SimpleShowLayout>
    </Show>
  );
};
