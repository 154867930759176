import * as React from 'react';
import { AppBar, TitlePortal } from 'react-admin';

import { Logo } from './Logo';

export const PlinqxAppBar = () => (
  <AppBar color='primary' sx={{ '& .RaAppBar-toolbar': { bgcolor: 'black' } }}>
    <Logo />
    <TitlePortal />
  </AppBar>
);
