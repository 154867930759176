import {
  Datagrid,
  List,
  Show,
  SimpleShowLayout,
  DateField,
  BooleanField,
  TextField,
  ShowButton,
  useRecordContext,
  useGetOne,
} from 'react-admin';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Link } from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';

import Loading from '../../components/Loading';
import Error from '../../components/Error';

const PackageEventTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <span>
      Package Event "{record.Name} Version {record.Version}"
    </span>
  );
};

export const PackageEventsButton = () => {
  const record = useRecordContext();
  const navigate = useNavigate();
  return (
    <Button
      component={Link}
      startIcon={<InventoryIcon />}
      color='primary'
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/Organizations/${record.id}/PackageEvents`);
      }}
    >
      Package Events
    </Button>
  );
};

export const PackageEventList = () => {
  return (
    <List empty={false} sort={{ field: 'created_at', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false}>
        <DateField source='created_at' showDate showTime label='Created At' />
        <TextField source='Name' label='Name' />
        <TextField source='Version' label='Version' />
        <BooleanField source='IsUpgrade' label='Is Upgrade' />
        <BooleanField source='IsPush' label='Is Push' />
        <TextField source='Event' label='Event' />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const OrganizationPackageEventList = () => {
  const { id: OrganizationId } = useParams();
  const { data, isLoading, error } = useGetOne('Organizations', { id: OrganizationId });
  if (!OrganizationId || isLoading) {
    return <Loading />;
  }
  if (error) return <Error />;
  return (
    <List
      resource='PackageEvents'
      title={`Package Events for "${data.Name}"`}
      empty={false}
      filter={{ OrganizationId: data.OrganizationId }}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField source='created_at' showDate showTime label='Created At' />
        <TextField source='Name' label='Name' />
        <TextField source='Version' label='Version' />
        <BooleanField source='IsUpgrade' label='Is Upgrade' />
        <BooleanField source='IsPush' label='Is Push' />
        <TextField source='Event' label='Event' />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const PackageEventShow = () => {
  return (
    <Show title={<PackageEventTitle />}>
      <SimpleShowLayout>
        <TextField source='OrganizationId' label='Organization Id' />
        <DateField source='created_at' showDate showTime label='Created At' />
        <TextField source='Name' label='Name' />
        <TextField source='Version' label='Version' />
        <TextField source='PreviousVersion' label='Previous Version' />
        <BooleanField source='IsUpgrade' label='Is Upgrade' />
        <BooleanField source='IsPush' label='Is Push' />
        <TextField source='Event' label='Event' />
        <TextField source='UserId' label='User Id' />
        <TextField source='UserName' label='User Name' />
        <TextField source='UserUsername' label='Username' />
        <TextField source='UserEmail' label='User Email' />
      </SimpleShowLayout>
    </Show>
  );
};
