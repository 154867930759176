import * as React from 'react';

export const Logo = () => {
  return (
    <img
      src='/assets/Plinqx_logotype_white_on_black.png'
      alt='Plinqx Logo'
      style={{ maxWidth: '150px', marginRight: '1em' }}
    />
  );
};
