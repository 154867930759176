import { fetchUtils } from 'react-admin';

const httpClient = (url, options = {}) => {
  options.credentials = 'include';
  return fetchUtils.fetchJson(url, options);
};

const getUser = () => {
  return httpClient(`${process.env.REACT_APP_SERVER_URL}/admin/api/user`);
};

const authProvider = {
  login: (params) => {
    // send username and password to the auth server and get back credentials
    return Promise.resolve();
  },
  checkError: (error) => {
    // when the dataProvider returns an error, check if this is an authentication error
    const status = error.status;
    if (status === 401 || status === 403) {
      return Promise.reject({ redirectTo: '/login' });
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: (params) => {
    // called when the user navigates to a new location, to check for authentication
    return getUser()
      .then(({ json }) => {
        if (json.status === 'ok') {
          return Promise.resolve();
        }
        return Promise.reject({ redirectTo: '/login' });
      })
      .catch((error) => {
        return Promise.reject({ redirectTo: '/login' });
      });
  },
  logout: () => {
    // remove local credentials and notify the auth server that the user logged out
    return httpClient(`${process.env.REACT_APP_SERVER_URL}/auth/logout`).then(() => {
      return Promise.resolve();
    });
  },
  getIdentity: () => {
    // get the user's profile
    // called when the user navigates to a new location, to check for authentication
    return getUser().then(({ json }) => {
      if (json.status === 'ok') {
        const { Username: id, DisplayName: fullName, Picture: avatar } = json;
        return Promise.resolve({ id, fullName, avatar, identity: json });
      }
      return Promise.reject();
    });
  },
  getPermissions: () => {
    // get the user permissions
    return Promise.resolve();
  },
};

export default authProvider;
