import {
  Datagrid,
  List,
  Show,
  SimpleShowLayout,
  DateField,
  TextField,
  ShowButton,
  useRecordContext,
  useGetOne,
} from 'react-admin';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Link } from '@mui/material';
import WebStoriesIcon from '@mui/icons-material/WebStories';

import Loading from '../../components/Loading';
import Error from '../../components/Error';

const LogTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>Log "#{record.id}"</span>;
};

export const LogsButton = () => {
  const record = useRecordContext();
  const navigate = useNavigate();
  return (
    <Button
      component={Link}
      startIcon={<WebStoriesIcon />}
      color='primary'
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/Organizations/${record.id}/Logs`);
      }}
    >
      Logs
    </Button>
  );
};

export const LogList = () => {
  return (
    <List empty={false} sort={{ field: 'created_at', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false}>
        <DateField source='created_at' showDate showTime label='Created At' />
        <TextField source='Type' label='Type' />
        <TextField source='OrganizationId' label='Organization Id' />
        <TextField source='Message' label='Message' />
        <TextField source='RequestMethod' label='Request Method' />
        <TextField source='RequestURL' label='Request URL' />
        <TextField source='RequestUser' label='Request User' />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const OrganizationLogList = () => {
  const { id: OrganizationId } = useParams();
  const { data, isLoading, error } = useGetOne('Organizations', { id: OrganizationId });
  if (!OrganizationId || isLoading) {
    return <Loading />;
  }
  if (error) return <Error />;
  return (
    <List
      resource='Logs'
      title={`Logs for "${data.Name}"`}
      empty={false}
      filter={{ OrganizationId: data.OrganizationId }}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField source='created_at' showDate showTime label='Created At' />
        <TextField source='Type' label='Type' />
        <TextField source='OrganizationId' label='Organization Id' />
        <TextField source='Message' label='Message' />
        <TextField source='RequestMethod' label='Request Method' />
        <TextField source='RequestURL' label='Request URL' />
        <TextField source='RequestUser' label='Request User' />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const LogShow = () => {
  return (
    <Show title={<LogTitle />}>
      <SimpleShowLayout>
        <DateField source='created_at' showDate showTime label='Created At' />
        <TextField source='Type' label='Type' />
        <TextField source='OrganizationId' label='Organization Id' />
        <TextField source='Message' label='Message' />
        <TextField source='RequestMethod' label='Request Method' />
        <TextField source='RequestURL' label='Request URL' />
        <TextField source='RequestPayload' label='Request Payload' />
        <TextField source='RequestUser' label='Request User' />
      </SimpleShowLayout>
    </Show>
  );
};
