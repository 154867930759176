import { Admin, Resource, fetchUtils, defaultTheme } from 'react-admin';
import { Route } from 'react-router-dom';
import simpleRestProvider from 'ra-data-simple-rest';

import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import InventoryIcon from '@mui/icons-material/Inventory';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import WebStoriesIcon from '@mui/icons-material/WebStories';

import { PlinqxLayout } from './layout';
import AuthProvider from './auth/AuthProvider';
import LoginPage from './auth/LoginPage';
import { OrganizationList, OrganizationShow } from './routes/Organizations';
import {
  PackageEventList,
  PackageEventShow,
  OrganizationPackageEventList,
} from './routes/PackageEvents';
import {
  LoginHistoryEventList,
  LoginHistoryEventShow,
  OrganizationLoginHistoryEventList,
} from './routes/LoginHistoryEvents';
import { LogList, LogShow, OrganizationLogList } from './routes/Logs';

import red from '@mui/material/colors/red';

const httpClient = (url, options = {}) => {
  options.credentials = 'include';
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(
  `${process.env.REACT_APP_SERVER_URL}/admin/api`,
  httpClient
);

const theme = {
  ...defaultTheme,
  palette: {
    mode: 'dark',
    primary: {
      light: '#abdb33',
      main: '#96D200',
      dark: '#699300',
      contrastText: '#fff',
    },
    secondary: {
      light: '#4b90af',
      main: '#1E759B',
      dark: '#15516c',
      contrastText: '#fff',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(
      ','
    ),
  },
};

const AdminPage = () => (
  <Admin
    basename='/admin'
    layout={PlinqxLayout}
    authProvider={AuthProvider}
    theme={theme}
    loginPage={LoginPage}
    dataProvider={dataProvider}
    requireAuth
    disableTelemetry
  >
    <Resource
      name='Organizations'
      options={{ label: 'Organizations' }}
      list={<OrganizationList />}
      show={<OrganizationShow />}
      icon={CorporateFareIcon}
    >
      <Route path=':id/PackageEvents' element={<OrganizationPackageEventList />} />
      <Route path=':id/LoginHistoryEvents' element={<OrganizationLoginHistoryEventList />} />
      <Route path=':id/Logs' element={<OrganizationLogList />} />
    </Resource>
    <Resource
      name='PackageEvents'
      options={{ label: 'Package Events' }}
      list={<PackageEventList />}
      show={<PackageEventShow />}
      icon={InventoryIcon}
    />
    <Resource
      name='LoginHistoryEvents'
      options={{ label: 'Login History Events' }}
      list={<LoginHistoryEventList />}
      show={<LoginHistoryEventShow />}
      icon={LockOpenIcon}
    />
    <Resource
      name='Logs'
      options={{ label: 'Logs' }}
      list={<LogList />}
      show={<LogShow />}
      icon={WebStoriesIcon}
    />
  </Admin>
);

function App() {
  return <AdminPage />;
}

export default App;
