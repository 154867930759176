import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useAuthProvider } from 'react-admin';
import { useNavigate } from 'react-router-dom';

import red from '@mui/material/colors/red';
import { defaultTheme } from 'react-admin';

function Copyright(props) {
  return (
    <Typography variant='body2' color='text.secondary' align='center' {...props}>
      {'Copyright © '}
      <Link color='inherit' href='https://plinqx.com/'>
        Plinqx Pty Ltd
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const customTheme = createTheme({
  ...defaultTheme,
  palette: {
    mode: 'dark',
    primary: {
      light: '#abdb33',
      main: '#96D200',
      dark: '#699300',
      contrastText: '#fff',
    },
    secondary: {
      light: '#4b90af',
      main: '#1E759B',
      dark: '#15516c',
      contrastText: '#fff',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(
      ','
    ),
  },
});

export default function LoginPage() {
  const [checked, setChecked] = useState(false);
  const authProvider = useAuthProvider();
  const navigate = useNavigate();
  useEffect(() => {
    authProvider
      .checkAuth()
      .then(() => {
        navigate('/');
      })
      .catch(() => {});
  }, [authProvider, navigate]);
  return (
    <ThemeProvider theme={customTheme}>
      <Grid container component='main' sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(/assets/Plinqx_brand_animation_v01_r02.gif)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'black',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar
              sx={{ m: 1, bgcolor: 'black' }}
              src='/assets/x_white_on_black.png'
              variant='square'
            ></Avatar>
            <Typography component='h1' variant='h5'>
              Sign in with Salesforce
            </Typography>
            <Box component='form' noValidate sx={{ mt: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(event) => setChecked(event.target.checked)}
                    inputProps={{ 'aria-label': 'Agree to Terms and Conditions' }}
                  />
                }
                label={
                  <>
                    I have read and agree to the
                    <Link href={process.env.REACT_APP_TERMS_URL} target='_blank' rel='noopener'>
                      {' '}
                      Terms of Service{' '}
                    </Link>
                    and
                    <Link href={process.env.REACT_APP_PRIVACY_URL} target='_blank' rel='noopener'>
                      {' '}
                      Privacy Policy{' '}
                    </Link>
                    .
                  </>
                }
                style={{ display: 'flex', justifyContent: 'center' }}
              />
              <Button
                href={`${process.env.REACT_APP_SERVER_URL}/auth/salesforce`}
                fullWidth
                variant='contained'
                color='primary'
                sx={{ mt: 3, mb: 2 }}
                disabled={!checked}
              >
                Production or Developer Edition
              </Button>
              <Button
                href={`${process.env.REACT_APP_SERVER_URL}/auth/salesforce-sandbox`}
                fullWidth
                variant='contained'
                color='secondary'
                sx={{ mt: 3, mb: 2 }}
                disabled={!checked}
              >
                Sandbox
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
