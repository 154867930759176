import {
  Datagrid,
  List,
  Show,
  SimpleShowLayout,
  DateField,
  BooleanField,
  TextField,
  ShowButton,
  useRecordContext,
  useGetOne,
} from 'react-admin';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Link } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import Loading from '../../components/Loading';
import Error from '../../components/Error';

const LoginHistoryEventTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>Login History Event "#{record.id}"</span>;
};

export const LoginHistoryEventsButton = () => {
  const record = useRecordContext();
  const navigate = useNavigate();
  return (
    <Button
      component={Link}
      startIcon={<LockOpenIcon />}
      color='primary'
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/Organizations/${record.id}/LoginHistoryEvents`);
      }}
    >
      Login History Events
    </Button>
  );
};

export const LoginHistoryEventList = () => {
  return (
    <List empty={false} sort={{ field: 'created_at', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false}>
        <DateField source='created_at' showDate showTime label='Logged In At' />
        <TextField source='Username' label='Username' />
        <TextField source='DisplayName' label='Display Name' />
        <TextField source='OrganizationURL' label='Organization URL' />
        <TextField source='IP' label='IP' />
        <TextField source='Browser' label='Browser' />
        <TextField source='OS' label='OS' />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const OrganizationLoginHistoryEventList = () => {
  const { id: OrganizationId } = useParams();
  const { data, isLoading, error } = useGetOne('Organizations', { id: OrganizationId });
  if (!OrganizationId || isLoading) {
    return <Loading />;
  }
  if (error) return <Error />;
  return (
    <List
      resource='LoginHistoryEvents'
      title={`Login History Events for "${data.Name}"`}
      empty={false}
      filter={{ OrganizationId: data.OrganizationId }}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField source='created_at' showDate showTime label='Logged In At' />
        <TextField source='Username' label='Username' />
        <TextField source='DisplayName' label='Display Name' />
        <TextField source='OrganizationURL' label='Organization URL' />
        <TextField source='IP' label='IP' />
        <TextField source='Browser' label='Browser' />
        <TextField source='OS' label='OS' />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const LoginHistoryEventShow = () => {
  return (
    <Show title={<LoginHistoryEventTitle />}>
      <SimpleShowLayout>
        <DateField source='created_at' showDate showTime label='Logged In At' />
        <TextField source='UserId' label='User Id' />
        <TextField source='OrganizationId' label='Organization Id' />
        <TextField source='Username' label='Username' />
        <TextField source='DisplayName' label='Display Name' />
        <TextField source='Email' label='Email' />
        <BooleanField source='EmailVerified' label='Email Verified' />
        <TextField source='FirstName' label='First Name' />
        <TextField source='LastName' label='Last Name' />
        <TextField source='Timezone' label='Timezone' />
        <TextField source='Picture' label='Picture' />
        <TextField source='UserType' label='User Type' />
        <TextField source='Language' label='Language' />
        <TextField source='Locale' label='Locale' />
        <TextField source='OrganizationURL' label='Organization URL' />
        <TextField source='IP' label='IP' />
        <TextField source='Browser' label='Browser' />
        <TextField source='OS' label='OS' />
      </SimpleShowLayout>
    </Show>
  );
};
